/** @jsxImportSource theme-ui */
import { useEffect } from 'react';
import { Switch, Route, Redirect, useRouteMatch, useLocation, useParams, useHistory } from 'react-router';
import NotFoundPage from './NotFoundPage';
import { Helmet } from 'react-helmet';

import QuotesPage from './QuotesPage';

const UserHome = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const params = useParams();

  // TODO: If path is /quotes/channel, redirect to /channel/quotes

  useEffect(() => {
    if (/[A-Z]/g.test(params.channel)) {
      history.replace(pathname.toLowerCase());
    }
  }, []);

  return (
    <>
    <Helmet>
      {params.channel} - FlareBot
    </Helmet>
    <Switch>
      {/* <Redirect sensitive from={`:channel(.*[A-Z]+.*)`} to={path.toLowerCase()}/> */}
      <Route path={`${path}/quotes`}>
        <QuotesPage/>
      </Route>
      <Route path={`${path}`} exact>
        <Redirect to={`${pathname}/quotes`}/>
      </Route>
      <Route path="*">
        <NotFoundPage/>
        {/* <Redirect to={{
          pathname: "/404",
          state: {
            url: pathname
          }
        }}/> */}
      </Route>
    </Switch>
  </>
  );
};

export default UserHome;