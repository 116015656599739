/** @jsxImportSource theme-ui */
import { Flex, Box } from 'theme-ui';

import TitleCard from '../components/TitleCard'; 

const Home = () => (
  <Flex sx={{
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  }}>
    <TitleCard>
      <Box sx={{
        marginTop: 3,
        textTransform: 'lowercase',
      }}>
        The Editor's Update 
      </Box>
    </TitleCard>
  </Flex>
);

export default Home;