/** @jsxImportSource theme-ui */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Flex, Field, Button, Themed, Grid, Spinner } from 'theme-ui';
import fetch from 'isomorphic-unfetch';

const EditQuoteDialog = ({data, onClose, onQuoteUpdated}) => {
  const id = data?.id || undefined;
  const channel = data?.channel;

  const [editController, setEditController] = useState(new AbortController());
  
  useEffect(() => {
    return editController.abort(); 
  }, []);

  const [quote, setQuote] = useState(data?.quote || '');
  const [game, setGame] = useState(data?.game || '');
  const [requestPending, setRequestPending] = useState(false);

  const handleSave = () => {
    if (requestPending) return false;
    const handleSaveAsync = async () => {
      setRequestPending(true);
      try {
        const editResponse = await fetch(
          `/api/quotes`,
          {
            method: 'PATCH',
            body: JSON.stringify({
              id,
              channel,
              quote,
              game
            })
          }
        );
        const editData = await editResponse.json();
        if (editResponse.ok && !editData.error) {
          setRequestPending(false);
          if (onQuoteUpdated) onQuoteUpdated(editData);
          if (onClose) onClose();
        } else {
          throw new Error(editData.error || "An unknown error occurred.");
        }
      } catch (error) {
        setRequestPending(false);
        console.error(error);
      }
    };

    if (data.quote !== quote || data.game !== game) {
      handleSaveAsync();
    } else {
      setRequestPending(false);
      if (onClose) onClose();
    }
  }

  const handleCancel = () => {
    if (onClose && !requestPending) onClose();
  }

  return (
    <Flex sx={{
      padding: ['32px','48px'],
      backgroundColor: 'card',
      boxShadow: '0px 3px 8px rgb(0, 0, 0, 50%)',
      flexDirection: 'column',
      width: ['100%', '32rem'],
      height: ['100%', 'auto'],
      maxWidth: '100%',
      maxHeight: '100%',
      overflowY: 'auto',
      zIndex: '2',
    }}>
      <Themed.h1 sx={{
        marginTop: '0',
      }}>Edit Quote</Themed.h1>
      <Grid sx={{
        gridTemplateColumns: '1fr',
        gridAutoRows: 'auto',
        gridAutoFlow: 'row',
        gridGap: '16px',
        '& label': {
          marginBottom: 1,
        }
      }}>
        <Field label="Quote" value={quote} onChange={(e)=>setQuote(e.target.value)}/>
        <Field label="Game" value={game} onChange={(e)=>setGame(e.target.value)}/>
        <Flex sx={{
          marginTop: 4,
          justifyContent: 'flex-end',
        }}>
          <Button variant="edit" onClick={handleCancel} sx={{
            marginRight: 2,
            cursor: 'pointer'
          }}>Cancel</Button>
          <Button onClick={handleSave} sx={{
            cursor: (requestPending ? 'not-allowed' : 'pointer'),
            display: 'flex',
            alignItems: 'center',
          }} disabled={requestPending}>
            {requestPending ? <Spinner sx={{
              height: '1.25em',
              width: '1.25em',
              color: 'inherit',
            }}/> : 'Save'}
          </Button>
        </Flex>
      </Grid>
    </Flex>
  );
};

export default EditQuoteDialog;