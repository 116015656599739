/** @jsxImportSource theme-ui */
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet';
import fetch from 'isomorphic-unfetch';
import { Box, Text, Themed } from 'theme-ui';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const BurnPage = () => {
  const query = useQuery();
  const [burnData, setBurnData] = useState({});
  const [burnDataLoading, setBurnDataLoading] = useState(true);
  const [burnDataError, setBurnDataError] = useState(false);
  
  useEffect(() => {
    const burnController = new AbortController();
    let subscribed = true;

    const asyncWrapper = async () => {
      setBurnDataLoading(true);
      setBurnDataError(false);
      const burnResponse = await fetch(
        `/api/burn?q=${query.get('q')}`,
        { signal: burnController.signal }
      );
      const data = await burnResponse.json();

      if (!subscribed) return false;
      
      if (burnResponse.ok) {
        setBurnData(data);
        setBurnDataError(false);
      } else {
        setBurnDataError(data.error || "An unknown error occurred.");
      }

      setBurnDataLoading(false);
    }

    if (query.get('q')) {
      asyncWrapper();
    } else {
      setBurnDataError("You must provide a state name.");
      setBurnDataLoading(false);
    }

    return () => {
      subscribed = false;
      burnController.abort();
    };
  }, []);

  const pageStyle = <style>{`
    body, html {
      background: white;
    }
  `}</style>;

  const Wrapper = ({children}) => (
    <>
      {pageStyle}
      <Box sx={{
        padding: 3,
        width: '100%',
        minHeight: '100%',
      }}>
        {children}
      </Box>
    </>
  );

  if (burnDataLoading) {
    return (
      <Wrapper>
        <Helmet>
          <title>Burn centers - FlareBot</title>
        </Helmet>
        <Text>Loading...</Text>
      </Wrapper>
    );
  }

  if (burnDataError) {
    return (
      <Wrapper>
        <Helmet>
          <title>Burn centers - FlareBot</title>
        </Helmet>
        <Text>{burnDataError}</Text>
      </Wrapper>
    );
  }

  if (burnData?.results && burnData.results.count === 0) {
    return (
      <Wrapper>
        <Helmet>
          <title>{burnData.state?.name ? `Burn centers in ${burnData.state?.name}` : `Burn`} - FlareBot</title>
        </Helmet>
        <Text>There are no burn centers in {burnData.state?.name}.</Text>
      </Wrapper>
    );
  }

  if (burnData?.results) {
    return (
      <Wrapper>
        <Helmet>
          <title>{burnData.state?.name ? `Burn centers in ${burnData.state?.name}` : `Burn`} - FlareBot</title>
        </Helmet>
        <Themed.h1 sx={{
          marginTop: '0',
        }}>
          Burn centers in {burnData.state?.name || ""}
        </Themed.h1>
        <ul sx={{
          listStyleType: 'none',
          paddingLeft: '0',
        }}>
          {burnData.results.data.map(v => (
            <li key={v.id}>
              <a href={`https://ameriburn.site-ym.com/members/?id=${v.id}`} target="_blank" rel="noreferrer" sx={{
                color: 'primary',
              }}>{v.name}</a>
            </li>
          ))}
        </ul>
        <Box sx={{
            fontSize: 'small',
            color: '#777',
            marginTop: '52px',
          }}>
            <em>
              Data provided by the <a href="https://ameriburn.org/" target="_blank" rel="noreferrer" sx={{
                color: '#777',
              }}>American Burn Association</a>.
            </em>
          </Box>
      </Wrapper>
    );
  };
};

export default BurnPage;