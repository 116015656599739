/** @jsxImportSource theme-ui */
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { Flex, Button } from 'theme-ui';

import TitleCard from '../components/TitleCard';
import {ReactComponent as Twitch} from '../twitch.svg';
import getCurrentUser from '../lib/getCurrentUser';
import { Helmet } from 'react-helmet';

const REDIRECT_URL = new URL('/login', window.location.href).href;

const twitchLoginURL = (state) => `https://id.twitch.tv/oauth2/authorize?client_id=qao022383kni77sp5mzbuafkaekz5c&redirect_uri=${encodeURIComponent(REDIRECT_URL)}&response_type=code&scope=moderation:read%20openid&state=${encodeURIComponent(state)}&claims=%7B%22id_token%22%3A%7B%22picture%22%3Anull,%22preferred_username%22%3Anull%7D,%22userinfo%22%3A%7B%22id_token%22%3A%7B%22picture%22%3Anull,%22preferred_username%22%3Anull%7D%7D%7D`;

const LoginPage = () => {
  const [loginState, setLoginState] = useState(null);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const asyncWrapper = async () => {
      const startLogin = location?.state?.startLogin;
      const continueTo = location?.state?.continue;

      let state = uuidv4().toLowerCase().replace(/[^[0-9a-f]/g, '');

      setLoginState(state);
      window.sessionStorage.setItem('state', state);

      // Use pushed history state to store the user's previous page if they came from an inline login button.
      if (continueTo) {
        window.sessionStorage.setItem('continue', continueTo);
      } else {
        window.sessionStorage.removeItem('continue');
      }

      if (startLogin) {
        window.location.href = twitchLoginURL(state);
      }

      const currentUser = await getCurrentUser(history);
      // console.log(currentUser);
      if (!currentUser.error) history.push(`/${currentUser.login}`);
    };
    asyncWrapper();
  }, []);

  return (
    <Flex sx={{
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Helmet>
        <title>FlareBot</title>
      </Helmet>
      <TitleCard>
        <a href={twitchLoginURL(loginState)} sx={{
          display: 'block',
          marginTop: 3,
        }}>
          <Button tabIndex="-1" variant="twitch" sx={{
            cursor: 'pointer',
            fontWeight: '600',
            fontFamily: 'body',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
          }}>
            <Twitch sx={{
              height: '1.5em',
              verticalAlign: 'bottom',
              marginRight: 2,
            }}/> Log in with Twitch
          </Button>
        </a>
      </TitleCard>
    </Flex>
  );
};

export default LoginPage;