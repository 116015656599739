/** @jsxImportSource theme-ui */
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router';
import { Grid, Flex, Box, Text, Themed, Button, Input, Spinner } from 'theme-ui';
import { DotsVerticalOutline } from "@graywolfai/react-heroicons";
import fetch from 'isomorphic-unfetch';
import { Helmet } from "react-helmet";

import UserMenuButton from '../components/UserMenuButton';
import EditQuoteDialog from '../components/EditQuoteDialog';

const QuoteCellSkeleton = () => {
  return (
    <div sx={{
      width: '100%',
      minWidth: '1.75rem',
      height: '1.75rem',
      borderRadius: '0.25rem',
      background: '#eee',
      position: 'relative',
      overflow: 'hidden',
      '&::before': {
        content: '""',
        width: '250px',
        backgroundImage: 'linear-gradient(to right, #eee, #fff 50%, #eee 100%)',
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        animation: 'quote-shimmer 1.25s linear infinite',
      }
    }}></div>
  );
};

const QuoteRowSkeleton = () => (
  <tr sx={{
    border: '1px solid',
    borderColor: '#bbb',
    borderWidth: '1px 0 1px 0',
    '&:first-of-type': {
      'borderTopColor': '#bbb',
    },
    '&:last-of-type': {
      'borderBottomColor': '#bbb',
    }
  }}>
    <td><QuoteCellSkeleton/></td>
    <td><QuoteCellSkeleton/></td>
    <td colSpan="2"><QuoteCellSkeleton/></td>
  </tr>
);

const QuoteCells = ({data, userIsModerator, requestPending, onEdit, onDelete, onRestore}) => {
  const [showMenu, setShowMenu] = useState(false);

  const menuRef = useRef(null);

  const handleToggleMenu = () => {
    setShowMenu(prev => !prev);
  }

  const handleDismissMenu = (e) => {
    if (e.target !== menuRef.current && (!e.target?.classList || !e.target.classList.contains('menu-button'))) {
      setShowMenu(false);
    }
  }

  const waitForIdle = (callback) => () => {
    if (!requestPending) callback();
  };

  useEffect(() => {
    if (showMenu) {
      window.addEventListener('mousedown', handleDismissMenu, {passive: true});
      window.addEventListener('scroll', handleDismissMenu, {passive: true});
    } else {
      window.removeEventListener('mousedown', handleDismissMenu, {passive: true});
      window.removeEventListener('scroll', handleDismissMenu, {passive: true});
    }

    return () => {
      window.removeEventListener('mousedown', handleDismissMenu, {passive: true});
      window.removeEventListener('scroll', handleDismissMenu, {passive: true});
    }
  }, [showMenu]);

  return (
    <>
      <td>{data.id}</td>
      <td>{(data.deleted && !userIsModerator ? "This quote has been deleted." : data.quote)}</td>
      <td>{(data.deleted && !userIsModerator ? null : data.game)}</td>
      { userIsModerator && 
        <td sx={{
          whiteSpace: 'nowrap',
          '&:last-of-type': {
            padding: '4px',
          },
          '& button': {
            padding: 2,
            cursor: (requestPending ? 'not-allowed' : 'pointer'),
            '&:not(:last-of-type)': {
              marginRight: 1,
            },
          }
        }}>
          <div sx={{
            display: ['none', 'inline-block'],
            'tr:not(:hover, :focus) &': {
              visibility: 'hidden',
            },
            '& button': {
              lineHeight: 'normal',
            },
            //padding: '0.5rem 1rem',
          }}>
            <Button variant="edit" onClick={waitForIdle(onEdit)}>Edit</Button>
            {
              data.deleted
              ? <Button variant="restore" onClick={waitForIdle(onRestore)} sx={{
                display: 'inline-grid',
                gridTemplateRows: 'auto',
                gridTemplateColumns: 'auto',
                justifyContent: 'center',
                alignItems: 'center',
                '& span': {
                  gridRow: '1 / 2',
                  gridColumn: '1 / 2',
                }
              }}>
                <span sx={{
                  visibility: requestPending ? 'hidden' : 'unset'
                }}>Restore</span>
                <span>
                  <Spinner sx={{
                    height: '1em',
                    width: '1em',
                    color: 'inherit',
                    margin: '0 auto',
                    display: requestPending ? 'block' : 'none'
                  }}/>
                </span>
              </Button>
              : <Button variant="delete" onClick={waitForIdle(onDelete)} sx={{
                display: 'inline-grid',
                gridTemplateRows: 'auto',
                gridTemplateColumns: 'auto',
                justifyContent: 'center',
                alignItems: 'center',
                '& span': {
                  gridRow: '1 / 2',
                  gridColumn: '1 / 2',
                }
              }}>
                <span sx={{
                  visibility: 'hidden',
                }}>Restore</span>
                <span>
                  {
                    requestPending
                    ? <Spinner sx={{
                        height: '1em',
                        width: '1em',
                        color: 'inherit',
                        margin: '0 auto',
                        display: requestPending ? 'block' : 'none'
                      }}/>
                    : <>Delete</>
                  }
                </span>
              </Button>
            }
          </div>
          <div ref={menuRef} onClick={handleToggleMenu} sx={{
            display: ['flex', 'none'],
            padding: '1rem',
            flexGrow: '1',
            alignItems: 'stretch',
            justifyContent: 'center',
            cursor: 'pointer',
            position: 'relative',
          }}>
            <DotsVerticalOutline sx={{
              height: '100%',
              width: '1.5rem',
              pointerEvents: 'none',
            }}/>
            <Flex sx={{
              display: (showMenu ? 'flex' : 'none'),
              position: 'absolute',
              top: '1rem',
              right: 'calc(100% - 1rem)',
              backgroundColor: 'card',
              flexDirection: 'column',
              boxShadow: '0px 3px 5px rgb(0, 0, 0, 50%)',
              userSelect: 'none',
              WebkitTapHighlightColor: 'transparent',
              overflow: 'hidden',
              borderRadius: '4px',
              '& > .menu-button': {
                padding: '0.75rem 1rem !important',
                borderRadius: '0 !important',
                borderWidth: '0 !important',
                marginRight: '0 !important',
                textAlign: 'left',
              }
            }}>
              <Button className="menu-button" variant="edit" onClick={waitForIdle(onEdit)}>Edit</Button>
              {
                data.deleted
                ? <Button className="menu-button" variant="restore" onClick={waitForIdle(onRestore)}>Restore</Button>
                : <Button className="menu-button" variant="delete" onClick={waitForIdle(onDelete)}>Delete</Button>
              }
            </Flex>
          </div>
        </td>
      }
    </>
  );
};

const checkQuotePropsAreEqual = (prev, next) => {
  return (
    prev.data.id === next.data.id &&
    prev.data.quote === next.data.quote &&
    prev.data.game === next.data.game &&
    prev.data.deleted === next.data.deleted &&
    prev.userIsModerator === next.userIsModerator &&
    prev.requestPending === next.requestPending
  );
};

const MemoizedQuoteCells = React.memo(QuoteCells, (prev, next) => {
  return (
    checkQuotePropsAreEqual(prev, next)
  );
});

const QuoteRow = (props) => (
  <tr sx={{
    border: '1px solid',
    borderColor: '#bbb',
    borderWidth: '1px 0 1px 0',
    display: props.hide ? 'none' : 'table-row',
    '& td:nth-of-type(-n+3)': {
      textDecoration: (props.data.deleted && props.userIsModerator ? 'line-through' : 'initial'),
      fontStyle: (props.data.deleted && !props.userIsModerator ? 'italic' : 'initial'),
      color: (props.data.deleted ? '#555' : 'inherit'),
      // padding: 'calc(0.5rem + 8px + 1px) calc(1rem)',
    },
    '&:hover': {
      backgroundColor: 'muted',
    },
  }}>
    <MemoizedQuoteCells {...props}/>
  </tr>
);

const MemoizedQuoteRow = React.memo(QuoteRow, (prev, next) => {
  return (
    checkQuotePropsAreEqual(prev, next) &&
    prev.hide === next.hide
  )
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const QuotesPage = () => {
  const channel = useParams().channel;
  const query = useQuery();
  const [searchValue, setSearchValue] = useState(query.get('q') || "");

  const [editorData, setEditorData] = useState({id: null, quote: null, game: null});
  const [editorOpen, setEditorOpen] = useState(false);

  const [quotes, setQuotes] = useState([]);
  const [quotesLoading, setQuotesLoading] = useState(false);
  const [quotesError, setQuotesError] = useState(false);

  const [pendingRequests, setPendingRequests] = useState([]);

  const [userData, setUserData] = useState(false);
  const [userDataLoading, setUserDataLoading] = useState(true);
  const [userIsModerator, setUserIsModerator] = useState(false);

  const [channelDisplayName, setChannelDisplayName] = useState(null);
  const [channelId, setChannelId] = useState(null);
  const [headerStuck, setHeaderStuck] = useState(window.scrollY > 0);

  const handleEditorOpen = (data) => {
    setEditorData(data);
    setEditorOpen(true);
  }

  const handleQuoteUpdated = (data) => {
    //TODO: restructure quotes as an object with ID keys to make it easier to find and update items.
    const index = quotes.findIndex((quote) => quote.id === data.id);
    if (index > -1) {
      setQuotes(prev => {
        let newQuotes = [...prev];
        newQuotes[index] = data;
        return newQuotes;
      });
    } else {
      console.error("Couldn't find the quote to be updated.");
    }
  }

  const addPendingRequest = (id) => {
    if (checkHasPendingRequest(id)) return true;
    setPendingRequests(prev => {
      let newPending = [...prev, id];
      return newPending;
    });
    return false;
  }

  const removePendingRequest = (id) => {
    setPendingRequests(prev => {
      let newPending = [...prev];
      return newPending.filter(v => v !== id);
    });
  }

  const checkHasPendingRequest = (id) => pendingRequests.includes(id);

  const handleDeleteQuote = (id) => {
    if (checkHasPendingRequest(id)) return false;
    
    const handleDeleteAsync = async () => {
      const deleteResponse = await fetch(
        `/api/quotes`,
        {
          method: 'PATCH',
          credentials: 'same-origin',
          body: JSON.stringify({
            id: id,
            channel: channelId,
            deleted: true
          })
        }
      );
      if (deleteResponse.ok) {
        handleQuoteUpdated(await deleteResponse.json());
      }
    };

    addPendingRequest(id);
    handleDeleteAsync().finally(() => {
      removePendingRequest(id);
    });
  }

  const handleRestoreQuote = (id) => {
    if (checkHasPendingRequest(id)) return false;

    const handleRestoreAsync = async () => {
      const restoreResponse = await fetch(
        `/api/quotes`,
        {
          method: 'PATCH',
          credentials: 'same-origin',
          body: JSON.stringify({
            id: id,
            channel: channelId,
            deleted: false
          })
        }
      );
      if (restoreResponse.ok) {
        handleQuoteUpdated(await restoreResponse.json());
      }
    };

    addPendingRequest(id);
    handleRestoreAsync().finally(() => {
      removePendingRequest(id);
    }); 
  }

  const handleLogout = () => {
    const handleLogoutAsync = async () => {
      await fetch(`/api/logout`);
      window.location.reload();
    }
    handleLogoutAsync();
  };

  useEffect(() => {
    let subscribed = true;
    const quotesController = new AbortController();
    let errorMessage = null;

    const getChannelAsync = async () => {
      const channelResponse = await fetch(
        `/api/channel?login=${channel}`,
        { signal: quotesController.signal }
      );
      const channelData = await channelResponse.json();
      if (channelResponse.ok) {
        setChannelDisplayName(channelData.displayName);
      }
    }

    getChannelAsync().catch((error) => {
      console.error("Error in channel async:", error);
    });

    // This is all sandwiched together to reduce load times and server burden on token renewal.
    // Assume an expired token for which a valid associated access token is stored in the database.
    // If the /api/me request and the /api/quotes requests are both sent at the same time, they will BOTH trigger the reauth flow.
    // The /api/me request driving the token revalidation MUST complete before the quotes request is sent.
    const getQuotesAsync = async () => {
      setQuotesLoading(true);
      setQuotesError(false);

      let userResponse = await fetch(
        '/api/me',
        { signal: quotesController.signal }
      );
      let userData = await userResponse.json();
      if (!userResponse.ok) {
        setUserData(false);
        setUserDataLoading(false);
      }
      if (subscribed && userResponse.ok) {
        setUserData(userData);
        setUserDataLoading(false);
      }
      
      const quoteResponse = await fetch(
        `/api/quotes?channel=${channel}`,
        { signal: quotesController.signal }
      );
      const quoteData = await quoteResponse.json();
      if (!quoteResponse.ok) {
        errorMessage = quoteData.error;
        setQuotesLoading(false);
        setQuotesError(errorMessage);
      }
      if (subscribed && quoteResponse.ok) {
        setQuotes(quoteData.quotes);
        setUserIsModerator(quoteData?.moderator || false);
        setChannelId(quoteData.channel);
        setQuotesLoading(false);
        setQuotesError(false);
      }
    };

    getQuotesAsync().catch((error) => {
      console.error("Error in quotes async:", error);
      setQuotesLoading(false);
      setQuotesError(errorMessage || "An unknown error occurred.");
      setUserData(false);
      setUserDataLoading(false);
    });

    return () => {
      quotesController.abort();
      setQuotesLoading(false);
      setUserData(false);
      setUserDataLoading(false);
      subscribed = false;
    }
  }, []);

  useEffect(() => {
    const queryValue = (searchValue ? `?q=${searchValue}` : ``);
    window.history.replaceState(null, null, `${window.location.pathname}${queryValue}`);
  }, [searchValue]);

  useEffect(() => {
    const scrollCallback = () => {
      setHeaderStuck(window.scrollY > 0);
    };
    window.addEventListener('scroll', scrollCallback, {passive: true});
    return () => {
      window.removeEventListener('scroll', scrollCallback, {passive: true});
    }
  }, []);

  // TODO: Consider moving the capitalization data out of the quote data request and to a dedicated endpoint.
  // When there are a lot of quotes, it's not uncommon for the request to take multiple seconds.
  // If we send the capitalization request separately, we can get that back much more quickly, and also use it on other pages.

  return (
    <Box sx={{
      width: '100%',
      minHeight: '100%',
    }}>
      <Helmet>
        <title>{channelDisplayName || channel}&rsquo;s Quotes - FlareBot</title>
      </Helmet>
      <style>
        {`
        @keyframes quote-shimmer {
          from {
            left: -250px;
          }
          to {
            left: calc(100% + 250px);
          }
        }`}
      </style>
      <Grid sx={{
        gridGap: '16px',
        gridTemplateColumns: ['1fr auto', '1fr 18rem auto'],
        gridAutoRows: 'auto',
        gridTemplateAreas: ['"title user" "search search"', '"title search user"'],
        backgroundColor: 'card',
        color: 'text',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1rem',
        boxShadow: headerStuck ? '0px -4px 10px rgba(0, 0, 0, 1)' : 'none',
        transition: 'box-shadow 30ms linear',
        borderBottom: '1px solid #bbb',
        position: 'sticky',
        top: 0,
        left: 0,
        right: 0,
        zIndex: '1',
      }}>
        <Themed.h1 sx={{
          fontWeight: 500,
          margin: '0',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          flex: '1 0 auto',
          maxWidth: '100%',
          gridArea: 'title',
          textAlign: 'left',
        }}>
          <Text sx={{
            textTransform: 'capitalize',
          }}>{channelDisplayName || channel}</Text>&rsquo;s Quotes
        </Themed.h1>
        <Input
          sx={{
            backgroundColor: '#eee',
            color: 'text',
            borderWidth: '1px',
            borderColor: '#bbb',
            borderRadius: '0.25rem',
            '&::placeholder': {
              color: '#333',
            },
            '&:hover, &:focus': {
              borderColor: 'text',
            },
            '&:focus': {
              backgroundColor: 'card',
              '&::placeholder': {
                color: 'transparent',
              }
            },
            fontFamily: 'body',
            gridArea: 'search',
          }}
          placeholder="Search quotes..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        >
        </Input>
        <div sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gridArea: 'user',
        }}>
          <UserMenuButton data={userData} loading={userDataLoading} logout={handleLogout}/>
        </div>
      </Grid>
      <Box sx={{
        width: '100%',
        maxWidth: '100%',
        overflowX: 'auto',
      }}>
        <table sx={{
          margin: '0 auto',
          width: '100%',
          height: '100%',
          borderCollapse: 'collapse',
          '& tr td, & tr th:nth-of-type(-n+3)': {
            padding: '0.5rem 1rem',
          },
          borderBottom: '1px solid #bbb',
        }}>
          <thead sx={{
            backgroundColor: '#eee',
          }}>
            <tr sx={{
              '& th': {
                textAlign: 'left',
                fontWeight: '600',
                '&:not(:last-of-type)': {
                  padding: '0 1rem',
                }
              }
            }}>
              <th sx={{ width: '5%' }}>ID</th>
              <th sx={{ width: '70%' }}>Quote</th>
              <th sx={{ width: '25%' }}>Game</th>
              {userIsModerator && <th sx={{
                padding: '0rem 4px',
              }}>
                <Text sx={{
                  display: ['none', 'inline'],
                }}>
                  Manage
                </Text>
              </th>}
            </tr>
          </thead>
          <tbody sx={{
            backgroundColor: 'card',
          }}>
            {!quotesLoading && quotes && quotes.map((v) => (
              <MemoizedQuoteRow
                key={v.id}
                data={v}
                onEdit={() => handleEditorOpen({...v, channel: channelId})}
                onDelete={() => handleDeleteQuote(v.id)}
                onRestore={() => handleRestoreQuote(v.id)}
                userIsModerator={userIsModerator}
                requestPending={checkHasPendingRequest(v.id)}
                hide={v.quote.toLowerCase().indexOf(searchValue.toLowerCase()) === -1}
              />
            ))}
            {quotesLoading && [...[,,,,]].map((_,i) => (
              <QuoteRowSkeleton key={i}/>
            ))}
          </tbody>
        </table>
        {quotesError && 
          <Box sx={{
            textAlign: 'center',
            marginTop: 4,
          }}>
            <Text>
              {quotesError}
            </Text>
          </Box>
        }
        {quotes && quotes.length === 0 && !quotesLoading && !quotesError &&
          <Box sx={{
            textAlign: 'center',
            marginTop: 4,
          }}>
            <Text>
              {channelDisplayName || channel} doesn't have any quotes.
            </Text>
          </Box>
        }
      </Box>
      {editorOpen && <Flex className="editor-backdrop" sx={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(255, 255, 255, 0.5)',
        position: 'fixed',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        zIndex: '2',
      }}>
        <Flex className="editor-veil" sx={{
          position: 'fixed',
          top: '0',
          right: '0',
          bottom: '0',
          left: '0',
        }} onClick={(e) => {
          setEditorOpen(false);
        }}></Flex>
        <EditQuoteDialog data={editorData} onClose={() => setEditorOpen(false)} onClick={(e) => e.stopPropagation()} onQuoteUpdated={handleQuoteUpdated}/>
      </Flex>}
    </Box>
  );
};

export default QuotesPage;