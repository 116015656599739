/** @jsxImportSource theme-ui */
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Flex, Box } from 'theme-ui';
import TitleCard from '../components/TitleCard';

const AuthFailedPage = ({ location }) => {

  useEffect(() => {
    if (location?.state?.url) {
      window.history.replaceState(null, "Login Failed - FlareBot", location.state.url);
    }
  }, []);

  return (
    <Flex sx={{
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Helmet>
        <title>Login Failed - FlareBot</title>
      </Helmet>
      <TitleCard title="Whoops!" version="">
        <Box sx={{
          marginTop: 3,
        }}>
          <Flex sx={{
            flexDirection: 'column',
          }}>
            <Box>We couldn't validate your login.</Box>
            <Box>Please <a href="/" sx={{
              '&:visited': {
                color: 'primary',
              }
            }}>
              try again
            </a>.</Box>
          </Flex>
        </Box>
      </TitleCard>
    </Flex>
  );
};

export default AuthFailedPage;