/** @jsxImportSource theme-ui */
import { useEffect } from 'react';
// import { useHistory } from 'react-router';
import { Flex, Box, /* Button */ } from 'theme-ui';
import TitleCard from '../components/TitleCard';

const NotFoundPage = ({ location }) => {
  // const history = useHistory();

  useEffect(() => {
    if (location?.state?.url) {
      window.history.replaceState(null, "Not Found - FlareBot", location.state.url);
    }
  }, []);

  return (
    <Flex sx={{
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <TitleCard title="Whoops!" version="">{/* 404 */}
        <Box sx={{
          marginTop: 3,
        }}>
          That page doesn't exist!
        </Box>
        {/* <Button variant="edit" onClick={(e) => {
          history.goBack();
        }} sx={{
          marginTop: 4,
          cursor: 'pointer',
        }}>
          Go Back
        </Button> */}
      </TitleCard>
    </Flex>
  );
};

export default NotFoundPage;