import React from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import { ThemeProvider } from 'theme-ui';
import { theme } from './theme';

import Home from './pages/HomePage';
import Login from './pages/LoginPage';
import UserPage from './pages/ChannelPage';
import NotFoundPage from './pages/NotFoundPage';
import AuthFailedPage from './pages/AuthFailedPage';
import AuthPage from './pages/AuthPage';
import BurnPage from './pages/BurnPage';

function App() {
  const { pathname } = useLocation();

  /**
   *  TODO: Consider changing the paths like so:
   *    - / has the login button.
   *    - /login is the callback from Twitch auth
   *    - /about has the about card with the version number (and maybe some other information).
   */

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)}/>
        <Route path="/" exact>
          <Login/>
        </Route>
        <Route path="/login" exact>
          <AuthPage/>
        </Route>
        <Route path="/about" exact>
          <Home/>
        </Route>
        <Route path="/burn">
          <BurnPage/>
        </Route>
        <Route path="/404" component={NotFoundPage}/>
        <Route path="/401" component={AuthFailedPage}/>
        <Route path="/:channel">
          <UserPage/>
        </Route>
        <Route path="*">
          <Redirect to="404"/>
        </Route>
      </Switch>
    </ThemeProvider>
  );
}

export default App;
