/** @jsxImportSource theme-ui */
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Flex, Box, Spinner, Button } from 'theme-ui';
import fetch from 'isomorphic-unfetch';

import TitleCard from '../components/TitleCard';
import getCurrentUser from '../lib/getCurrentUser';
import AuthFailedPage from './AuthFailedPage';
import { Helmet } from 'react-helmet';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const syncModerators = async () => {
  try {
    const modResponse = await fetch(`/api/moderators`);
    if (!modResponse.ok) {
      return true;
    }
    return false;
  } catch (error) {
    return true;
  }
}

const AuthPage = () => {
  const [loadingMessage, setLoadingMessage] = useState('Validating session...');
  const [errorOccurred, setErrorOccurred] = useState(false);

  const storedState = sessionStorage.getItem('state');
  const query = useQuery();
  const history = useHistory();

  useEffect(() => {
    let subscribed = true;
    const asyncWrapper = async () => {     
      const code = query.get('code');
      const state = query.get('state');
      const error = query.get('error');
      // const continueTo = query.get('continue');

      const continueTo = window.sessionStorage.getItem('continue');
      window.sessionStorage.removeItem('continue');

      if (code === null && error === null) {
        const currentUser = await getCurrentUser(history);
        if (!currentUser.error) {
          if (continueTo !== null) {
            history.push(continueTo);
          } else {
            history.push(`/${currentUser.login}`);
          }
          return false;
        }
      }

      /**
       * Redirect to AuthFailedPage immediately in the following circumstances:
       * - If the stored state value doesn't match the one returned by Twitch.
       * - If the query params contain "error".
       * - If the query params don't contain "error" or "code".
       */

      if (state !== storedState || error !== null || (code === null && error === null)) {
        setErrorOccurred(true);
        return false;
      }

      setLoadingMessage("Waiting for Twitch...");

      let data = null;

      try {
        const response = await fetch(`/api/authorize?code=${encodeURIComponent(code)}`);
        if (!response.ok) {
          throw new Error(`Authorization endpoint returned status code ${response.status}.`);
        } else {
          data = await response.json();
        }
      } catch (error) {
        setErrorOccurred(true);
        return false;
      }

      setLoadingMessage("Syncing moderators...");

      // syncModerators returns true if an error occurred.
      const modSyncResult = await syncModerators();

      if (modSyncResult) {
        setErrorOccurred(true);
      }

      // If a path was stored before the login, redirect the user back.
      if (continueTo !== null) {
        history.push(continueTo);
      } else {
        history.push(`/${data.login}`);
      }
    }
    asyncWrapper();
    return () => subscribed = false;
  }, []);

  if (errorOccurred) {
    return <AuthFailedPage/>;
  }

  return (
    <Flex sx={{
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Helmet>
        <title>Logging in - FlareBot</title>
      </Helmet>
      <TitleCard title="FlareBot" version="4.0">
        <Box sx={{
          marginTop: 3,
        }}>
          <Button disabled variant="twitch" sx={{
            height: '2.5em',
            fontWeight: '600',
            fontFamily: 'body',
          }}>
            <Spinner size="24" sx={{
              color: 'card',
              margin: 0,
              padding: 0,
              verticalAlign: 'bottom',
              marginRight: 2,
            }}/> {loadingMessage}
          </Button>
        </Box>
      </TitleCard>
    </Flex>
  );
};

export default AuthPage;