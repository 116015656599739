export const theme = {
  breakpoints: ['40em', '52em', '64em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body:
      'Open Sans, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'Fira Sans',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    text: '#000',
    background: 'hsl(261, 43%, 98%)',
    card: '#fff',
    primary: '#07c',
    secondary: '#30c',
    muted: '#f6f6f6',
    twitch: '#9146FF',
    action: {
      border: '#bbb',
      background: '#eee'
    },
    danger: {
      'border': 'hsl(3, 72%, 50%)',
      //'background': 'hsl(3, 72%, 90%)'
      background: 'hsl(3, 72%, 50%)',
    }
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
  },
  buttons: {
    twitch: {
      color: 'white', /* #F0F0FF */
      bg: 'twitch'
    },
    edit: {
      color: 'text',
      bg: 'white',
      border: '1px solid',
      borderColor: 'action.border',
      '&:hover, &:active': {
        bg: 'action.background',
      }
    },
    delete: {
      color: 'text',
      bg: 'white',
      border: '1px solid',
      borderColor: 'action.border',
      '&:hover, &:active': {
        color: 'white',
        // bg: 'hsl(3, 72%, 88%)',
        // borderColor: 'hsl(3, 72%, 63%)',
        bg: 'danger.background',
        borderColor: 'danger.border',
      },
    },
    restore: {
      color: 'text',
      bg: 'white',
      border: '1px solid',
      borderColor: 'action.border',
      '&:hover, &:active': {
        color: 'white',
        // bg: 'hsl(108deg 70% 88%)', //'primary',
        // borderColor: 'hsl(108, 62%, 50%)', //'primary',
        bg: 'primary',
        borderColor: 'primary'
      }
    }
  },
  styles: {
    root: {
      margin: 0,
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    a: {
      color: 'primary',
    },
    h1: {
      variant: 'text.heading',
      fontSize: 5,
      fontWeight: '500',
    },
    h2: {
      variant: 'text.heading',
      fontSize: 4,
    },
    h3: {
      variant: 'text.heading',
      fontSize: 3,
    },
    h4: {
      variant: 'text.heading',
      fontSize: 2,
    },
    h5: {
      variant: 'text.heading',
      fontSize: 1,
    },
    h6: {
      variant: 'text.heading',
      fontSize: 0,
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
  },
}