/** @jsxImportSource theme-ui */
import { Flex, Box, Text, Themed } from 'theme-ui';

const TitleCard = ({children, title, version}) => {
  const hideVersion = typeof version !== 'undefined' && version.length === 0;

  return (
    <Flex sx={{
      width: '24rem',
      height: '16rem',
      backgroundColor: 'card',
      borderRadius: '1px',
      boxShadow: '2px 2px 5px rgb(0 0 0 / 40%)',
      alignItems: 'center',
      justifyContent: 'center',
      '@media screen and (max-width: 24rem)': {
        height: '100%',
      },
    }}>
      <Box sx={{
        textAlign: 'center',
      }}>
        <Themed.h1 sx={{
          margin: 0,
          fontFamily: 'text.heading',
        }}>
          <Text sx={{
            fontWeight: '600',
          }}>
            { title || 'FlareBot' }
          </Text>
          { !hideVersion && <small sx={{
            fontWeight: '400',
            fontFamily: 'body',
            fontSize: 3,
            marginLeft: 2
          }}>{ typeof version !== 'undefined' ? version : '4.0' }
          </small> }
        </Themed.h1>
        {children}
      </Box>
    </Flex>
  );
};

export default TitleCard;