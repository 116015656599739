/** @jsxImportSource theme-ui */
import { useState, useEffect, useRef } from 'react';
import { Flex, Box, Button } from 'theme-ui';
import { useHistory, useLocation } from 'react-router';

import { ReactComponent as Twitch } from '../twitch.svg';

const UserMenuButton = ({data, loading, logout}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [showMenu, setShowMenu] = useState(false);

  const handleToggleMenu = () => {
    setShowMenu(prev => !prev);
  }

  const handleDismissMenu = (e) => {
    if (!e.target?.classList || (!e.target.classList.contains('logout-button') && !e.target.classList.contains('logout-wrapper'))) {
      setShowMenu(false);
    }
  }

  useEffect(() => {
    if (showMenu) {
      window.addEventListener('mousedown', handleDismissMenu, {passive: true});
    } else {
      window.removeEventListener('mousedown', handleDismissMenu, {passive: true});
    }

    return () => {
      window.removeEventListener('mousedown', handleDismissMenu, {passive: true});
    }
  }, [showMenu]);

  if (loading) {
    return (
      <>
        {/* @emotion/react kept throwing errors when I tried to use either the object or template literal syntax, so here we are. */}
        <style>
          {`@keyframes shimmer { 0% { background-position: calc(-1 * (2.5rem + 2px)); } 75%, 100% { background-position: calc(2.5rem + 2px); } }`}
        </style>
        <Box sx={{
          width: 'calc(2.5rem + 2px)',
          height: 'calc(2.5rem + 2px)',
          background: '#eee',
          borderRadius: 'calc(1.25rem + 1px)',
          overflow: 'hidden',
          cursor: 'pointer',
          backgroundSize: 'calc(2 * (2.5rem + 2px)) 100%',
          backgroundImage: 'linear-gradient(to right, #eee, #eee 10%, #f6f6f6 30%, #f6f6f6 30%, #eee 50%, #eee)',
          animationName: 'shimmer',
          animationTimingFunction: 'linear',
          animationDuration: '1.5s',
          animationFillMode: 'forwards',
          animationIterationCount: 'infinite',
        }}></Box>
      </>
    )
  }

  return (
    <>
      { data && !data?.error ? (
        <Box className="" sx={{
          width: 'calc(2.5rem + 2px)',
          height: 'calc(2.5rem + 2px)',
          position: 'relative',
        }}>
          <Box className="logout-wrapper" onClick={handleToggleMenu} sx={{
            width: 'calc(2.5rem + 2px)',
            height: 'calc(2.5rem + 2px)',
            background: '#eee',
            borderRadius: 'calc(1.25rem + 1px)',
            overflow: 'hidden',
            cursor: 'pointer',
          }}>
            <img className="logout-wrapper" src={data.profileImageURL || null} alt={data.displayName || null} sx={{
              width: 'calc(100% + 2px)',
              height: 'calc(100% + 2px)',
              margin: '-1px',
              objectFit: 'cover',
            }}/>
            <Flex sx={{
                display: (showMenu ? 'flex' : 'none'),
                position: 'absolute',
                top: 'calc(100% + 2px)',
                right: '0%',
                backgroundColor: 'card',
                flexDirection: 'column',
                boxShadow: '0px 2px 3px rgb(0, 0, 0, 40%)',
                userSelect: 'none',
                WebkitTapHighlightColor: 'transparent',
                overflow: 'hidden',
                borderRadius: '4px',
                '& > .menu-button': {
                  padding: '0.75rem 1rem !important',
                  borderRadius: '0 !important',
                  borderWidth: '0 !important',
                  marginRight: '0 !important',
                  textAlign: 'left',
                }
              }}>
                <Button className="logout-button" variant="edit" sx={{
                  whiteSpace: 'nowrap',
                  cursor: 'pointer',
                }} onClick={(e) => {
                  if (logout) logout();
                }}>Log out</Button>
              </Flex>
          </Box>
        </Box>
      ) : (
        <Button tabIndex="-1" variant="twitch" sx={{
          cursor: 'pointer',
          fontWeight: '600',
          fontFamily: 'body',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '100%',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'twitch',
        }} onClick={(e) => {
          history.push('/', {
            startLogin: true,
            continue: `${pathname}${window.location?.search || ''}`,
          });
        }}>
          <Twitch sx={{
            height: '1.5em',
            verticalAlign: 'bottom',
            marginRight: 2,
            display: ['none', 'none', 'inline-block']
          }}/> Log in<span sx={{
            display: ['none', 'none', 'inline'],
          }}> with Twitch</span>
        </Button>
      )}
    </>
  );
}

export default UserMenuButton;