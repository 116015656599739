import fetch from 'isomorphic-unfetch';

const getCurrentUser = async () => {
  let validateResponse = null;
  let fetchData = null;

  try {
    fetchData = await fetch('/api/me', {
      credentials: 'same-origin',
    });
    validateResponse = await fetchData.json();
  } catch (error) {
    console.error("Error in validation:", error);
    return {
      error: "An error occurred during validation.",
    };
  }
  
  return validateResponse;
}

export default getCurrentUser;